import * as d3 from 'd3';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import * as _ from 'lodash';
import sift from 'sift';

import autoTransform from './autoTransform';
import shape from './dataShape';
import dataLog from './data.json';

const moment = extendMoment(Moment);

/* 
The DATA MACHINE:
- provides all the crunching functionality required for the data page to render pretty graphs.

WORK PAUSED for a moment to explore using D3
*/

const getSubpaths = (field, prefix) => {
  const thisPath = prefix ? `${prefix}.${field.name}` : field.name;
  const result = [thisPath];
  if (field.subFields) {
    field.subFields.forEach( subField => result.push(...getSubpaths(subField, thisPath)));
  } else if (field.type === "list") {
    // TODO: This is brittle and works only for moneySpent.
    // Basically, if a field is a list, then look for its element fields that should be aggregated on (ie, become paths)
    // Only works for enum fields
    const pathField = field.elements.find( el => el.aggregate === true);
    if (pathField && pathField.values) {
      pathField.values.forEach( v => { result.push(`${thisPath}.${v}`) })
    }
  }
  return result;
}

const shapePaths = shape.fields.reduce( (agg, el) => agg.concat(...getSubpaths(el)), []);

const getPathsFromData = (data) => Array.from(new Set(data.map(d => d.path)));

const naiveLabelFromPath = (path) => _.startCase(path.split(".").reverse()[0]);

// TODO: make recursive, and make it understand nested structure
const getPathShape = (path) => shape.fields.find( el => el.name === path ) || { label: naiveLabelFromPath(path)};

class Machine {
  constructor(data) {
    this.data = data || autoTransform(dataLog);
    this.paths = getPathsFromData(this.data); //shapePaths; // Trying to predict/generate paths was a PITA
  }

  getDates() {
    return Array.from(new Set(this.data.map( d => d.date))).map( d => moment(d, "YYYY-MM-DD"));
  }

  // Min and max dates in set
  getDateInterval() {
    return d3.extent(this.getDates());
  }

  // List of all dates between min and max dates, inclusive
  getDateRange() {
    return Array.from(moment.range(this.getDateInterval()).by('day'));
  }

  getDateRangeStrings() {
    return this.getDateRange().map( d => d.format("YYYY-MM-DD"));
  }

  where(query) {
    return new Machine(sift(query, this.data)); // Allows chaining to other methods
  }

  // Limit the paths of the included data. mutating
  setPaths(query = {}) {
    this.paths = sift(query, this.paths);
    this.data = sift({ path: { $in: this.paths }}, this.data);
    // console.log("New Paths:", this.paths);
    // console.log("New Data:", this.data);
    return this;
  }

  /*
    [
      { id: 'enthusiasm.trip',
        data: [
          { x: "2019-01-25", y: 2 }
        ]
      }
    ]
  */
  forNivoLine() {
    const groupedByPath = d3.nest()
      .key( d => d.path)
      .entries(this.data);

    console.log("Grouped by Path:", groupedByPath);

    return groupedByPath.map( el => {
      const points = el.values.map( v => ({ x: v.date, y: v.value }));
      return { id: el.key, data: points };
    })
  }

  /*
  { 
    keys: [ 'moneySpent.lodging', 'moneySpent.foodIn' ],
    values: [
      { "moneySpent.lodging": 5, "moneySpent.foodIn": 5 }
    ]
  }

  1 set of values for each day. 
  */
  forNivoStream() {
    const keys = this.paths;
    const labels = keys.map( key => ({ key, label: getPathShape(key).label }));
    const defaultValues = {};
    keys.forEach( k => { defaultValues[k] = 0 }); // Makes sure that every day has a default value for every key. TODO: read defaults from shape
    const groupedByDate = d3.nest()
      .key( d => d.date)
      .entries(this.data);

    const values = this.getDateRangeStrings().map( date => {
      const result = Object.assign({}, defaultValues);
      const valuesOnDate = (groupedByDate.find( el => el.key === date) || {}).values || [];
      valuesOnDate.forEach( v => { result[v.path] = v.value });
      return result;
    })
    
    return { keys, labels, values }
  }
}

export default Machine;

