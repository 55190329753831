import * as d3 from 'd3';


// Apply some automatic transforms to the data based on patterns I want to access it.
// Ugly for now.
const autoTransform = (data) => {
  let newData = data.slice();

  // Money spent is a list of things spent in certain categories.
  // This aggregates those individual lists up to top-level data points ( ie, moneySpent.vanFuel )
  const moneySpentEntries = newData.filter( el => el.path === 'moneySpent');

  moneySpentEntries.forEach( entry => {
    const rollup = d3.nest()
      .key( d => d.category)
      .rollup( v => d3.sum(v, el => el.amount) )
      .entries(entry.listValues)
      
    // console.log("Rollup:", rollup);

    rollup.forEach( group => {
      newData.push({ path: `moneySpent.${group.key}`, date: entry.date, value: group.value })
    });
  })

  // Convert paths with a person to a person being the end of the path.
  // Ie, person="kendall" path="whatever" -> path="whatever.kendall"
  // Not sure if this will cause more problems in plotting.

  newData = newData.map( el => {
    if (el.person) {
      return Object.assign({}, el, { person: undefined, path: `${el.path}.${el.person}`})
    }
    return el
  })

  // console.log("AutoTransformed Data:", newData);
  return newData;
}

export default autoTransform;