import React from 'react';
import { HeatMap, ResponsiveHeatMap } from '@nivo/heatmap';
import { ResponsiveStream } from '@nivo/stream'
import Machine from '../../../data/dataMachine';

import update from 'update-immutable';


class MoneySpent extends React.Component {
  state = {
    data: undefined
  };

  componentDidMount() {
    // this.data = {};
    this.machine = new Machine();
    this.getData();
  }

  getData() {
    const data = this.machine.setPaths({ $regex: /^moneySpent\./ }).forNivoStream();
    const keyFilters = {};
    data.keys.forEach(key => { keyFilters[key] = !["moneySpent.capital", "moneySpent.vanImprovement"].includes(key) });
    this.setState({
      data,
      keyFilters
    });
  }

  getLabelForKey = (key) => {
    const { data } = this.state;
    const { labels } = data;
    return (labels.find(el => el.key === key) || {}).label || key;
  }

  handleChangeFilter = ({ target }) => {
    this.setState({ keyFilters: update(this.state.keyFilters, { [target.name]: { $set: !this.state.keyFilters[target.name] } }) })
  }

  render() {
    // console.log(this.state.data);
    const { data, keyFilters } = this.state;
    if (!data) return "Loading..."
    const showKeys = Object.keys(keyFilters).filter(k => keyFilters[k] === true);
    const { keys = [], values = [] } = data;
    return (
      <div className="chartContainer">
        <div className="chart" style={{ height: 600 }}>
          <ResponsiveStream
            keys={showKeys}
            data={values}
            margin={{
              "top": 20,
              "right": 20,
              "bottom": 50,
              "left": 60
            }}
            xScale={{
              "type": "point"
            }}
            yScale={{
              "type": "linear",
              "min": 0,
              "max": "auto"
            }}
            axisLeft={{
              legend: 'Money ($)',
              legendPosition: 'center',
              legendOffset: -40
            }}
            axisBottom={{
              legend: 'Trip Day',
              legendPosition: 'center',
              legendOffset: 40
            }}
            minY={0}
            maxY="auto"
            curve="monotoneX"
            colors="dark2"
            offsetType="none"
          />
        </div>
        <div className="filter">
          <h4>Filter</h4>
          {keys.sort().map(key => (
            <div key={key}>
              <input name={key} type="checkbox" checked={keyFilters[key]} onChange={this.handleChangeFilter} />&nbsp;
            <label>{this.getLabelForKey(key)}</label>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default MoneySpent;
