import React from 'react';
import {
  Container,
  Header,
  Icon,
  Message,
  Segment
} from 'semantic-ui-react';
import { Link } from 'gatsby';
import Helmet from "react-helmet";
import { tagData, data } from '../../data/prepareData';
import styles from './data.module.scss';

// Charts
import MoneySpent from '../components/Data/MoneySpent';


// import 'semantic-ui-css/semantic.min.css';


class DataPage extends React.Component {
  state = {}

  render() {
    return (
      <Container>
        <Helmet>
          <body className="semantic" />
        </Helmet>
        <div></div>
        <Header icon textAlign='center'>
          <i className="circular icon" >&#8721;</i>
          <Header.Content>Van Life Data Project</Header.Content>
          <Header.Subheader><Link to='/'>Back to HiatusSouth</Link></Header.Subheader>
        </Header>
        <Message color='blue'>
          This page is definitely a work-in-progress - check back later to see more!
        </Message>
        <Header attached='top'>Money Spent</Header>
        <Segment attached>
          <MoneySpent />
        </Segment>
      </Container>
    )
  }
}

export default DataPage;